<template>
  <div class="row g-2 mb-2">
    <div class="col-md-3">
      <div class="card">
        <div class="card-header bg-body d-flex justify-content-between">
          <span>{{ $t("asset_stats.menu") }}</span>
          <span><i class="bi bi-list"></i></span>
        </div>
        <div class="list-group list-group-flush">
          <a
            href="#"
            class="
              active
              list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center
            "
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-app-indicator"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t("asset_stats.overview") }}
              </div>
            </div>
          </a>
          <a
            href="#"
            class="
              list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center
            "
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-piggy-bank"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t("asset_stats.supply") }}
              </div>
            </div>
            <span class="badge ms-auto text-dark border fw-normal"
              >-</span
            >
          </a>
          <a
            href="#"
            class="
              list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center
            "
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-person-square"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t("asset_stats.holders") }}
              </div>
            </div>
            <span class="badge ms-auto text-dark border fw-normal"
              >-</span
            >
          </a>
          <a
            href="#"
            class="
              list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center
            "
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-coin"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t("asset_stats.price") }}
              </div>
            </div>
            <span class="badge ms-auto text-dark border fw-normal"
              >$ -</span
            >
          </a>
          <a
            href="#"
            class="
              list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center
            "
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi-arrow-right-circle-fill"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t("asset_stats.payments") }}
              </div>
            </div>
            <span class="badge ms-auto text-dark border fw-normal"
              >-</span
            >
          </a>
          <a
            href="#"
            class="
              list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center
            "
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-arrow-left-right"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t("asset_stats.trades") }}
              </div>
            </div>
            <span class="badge ms-auto text-dark border fw-normal"
              >-</span
            >
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card">
        <div class="card-header bg-body d-flex justify-content-between">
          <span>{{ $t("asset_stats.asset_information") }}</span>
        </div>
        <div class="card-body">
          <ul class="list-group">
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-person-square me-3"></i
                >{{ $t("asset_stats.asset_issuer") }}
              </div>
              <div class="w-75 py-2 px-3">
                <span
                  class="
                    align-self-center
                    small
                    font-monospace
                    text-decoration-none
                  "
                  >-</span
                >
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0 mt-1">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-tag-fill me-3"></i>{{ $t("asset_stats.label") }}
              </div>
              <div class="w-75 py-2 px-3">
                <span
                  class="
                    align-self-center
                    small
                    font-monospace
                    text-decoration-none
                  "
                >
                  -
                </span>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0 mt-1">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-globe me-3"></i>{{ $t("asset_stats.website") }}
              </div>
              <div class="w-75 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0 mt-1">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-arrow-right-circle-fill me-3"></i
                >{{ $t("asset_stats.payments") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
              <div class="w-25 py-2 px-3">
                <i class="bi bi-arrow-right-circle-fill me-3"></i
                >{{ $t("asset_stats.volume") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-arrow-left-right me-3"></i
                >{{ $t("asset_stats.trades") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
              <div class="w-25 py-2 px-3">
                <i class="bi bi-arrow-left-right me-3"></i
                >{{ $t("asset_stats.volume") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AssetStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
