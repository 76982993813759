export const capitalize = (string) => {
    return string[0].toUpperCase() + string.slice(1);
}
export const splitAndCapitalize = (string, delimiter) => {
    return string.toLowerCase().split(delimiter).map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
}

export const formatNumberLocale = (number) => {
    return new Intl.NumberFormat(undefined, {notation: 'compact', maximumFractionDigits: 2}).format(number);
}

export const removeTrailingZeros = (number) => {
    // Convert the number to a string
    const numberString = number.toString();

    // Use a regular expression to remove trailing zeros
    const trimmedNumberString = numberString.replace(
        /(\.[0-9]*[1-9])0+$/,
        "$1"
    );

    // Convert the trimmed string back to a number
    const trimmedNumber = trimmedNumberString;

    return trimmedNumber.toLocaleString();
}

export const camelCaseToNormalTextWithCaps = (camelCaseString) => {
    let result = [];

    for (let i = 0; i < camelCaseString.length; i++) {
        const char = camelCaseString.charAt(i);

        if (i === 0) {
            result.push(char.toUpperCase());
        } else if (char === char.toUpperCase() && char !== ' ') {
            result.push(' ', char);
        } else {
            result.push(char);
        }
    }

    return result.join('');
}

