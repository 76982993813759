<template>
  <div class="card" style="height: 288px">
    <div
      class="
        card-header
        bg-body
        d-flex
        justify-content-between
      "
    >
      <span class="">{{
        $t(`asset_stats.${graph.replace("gf_", "")}`)
      }}</span>
    </div>
    <div
      class="card-body"
      style="margin-right: 15px"
      ref="chartContainer"
    ></div>
  </div>
</template>

<script>
import {
  defineComponent,
  watch,
  ref,
  onMounted,
  onUnmounted,
  computed,
} from "vue";
import { useStore } from "vuex";
import { createChart } from "lightweight-charts";
import {
  createTooltip,
  chartTooltip,
  chartOptions,
  seriesOptions,
  avgLineValue,
  valueFormatter,
} from "@/utils/chart.js";
export default defineComponent({
  name: "AssetStatsChart",
  props: {
    graph: { type: String },
    assetCode: { type: String },
    assetIssuer: { type: String },
  },
  setup(props) {
    const store = useStore();
    const chartContainer = ref();
    const toolTip = ref(createTooltip());

    let series;
    let chart;

    onMounted(() => {
      store.dispatch("assets/fetchAssetChartsData", {
        graph: props.graph,
        assetCode: props.assetCode,
        assetIssuer: props.assetIssuer,
      });
      chart = createChart(chartContainer.value, chartOptions);
      chart.applyOptions({
        localization: {
          priceFormatter: valueFormatter(props.graph),
        },
      });
      addSeriesAndData(assetsData.value);

      chartContainer.value.appendChild(toolTip.value);
      chart.subscribeCrosshairMove(displayTooltip);

      chart.timeScale().fitContent();
    });

    const assetsData = computed(() => {
      return store.getters["assets/getAssetChartsData"].data.map((item) => {
        return {
          time: Math.floor(Date.parse(item.date) / 1000),
          value: parseFloat(item[props.graph]),
        };
      });
    });
    const avgValue = computed(
      () =>
        assetsData.value.reduce((total, next) => total + next.value, 0) /
        assetsData.value.length
    );

    const resizeHandler = () => {
      if (!chart || !chartContainer.value) return;
      const dimensions = chartContainer.value.getBoundingClientRect();
      chart.resize(dimensions.width, dimensions.height);
    };
    const displayTooltip = (param) =>
      chartTooltip(
        param,
        chartContainer.value,
        series,
        props.graph,
        toolTip.value,
        "asset_stats"
      );

    // Creates the chart series and sets the data.
    const addSeriesAndData = (assetsData) => {
      series = chart["addAreaSeries"](seriesOptions);
      series.setData(assetsData);
      series.createPriceLine(avgLineValue(avgValue.value));
      chart.timeScale().fitContent();
      window.addEventListener("resize", resizeHandler);
    };

    watch(
      () => props.graph,
      (graph) => {
        store.dispatch("assets/fetchAssetChartsData", {
          graph: graph,
          assetCode: props.assetCode,
          assetIssuer: props.assetIssuer,
        });
      }
    );
    watch(
      () => assetsData.value,
      (assetsData) => {
        if (series && chart) {
          chart.removeSeries(series);
        }
        chart.applyOptions({
          localization: {
            priceFormatter: valueFormatter(props.graph),
          },
        });
        addSeriesAndData(assetsData);
      }
    );

    onUnmounted(() => {
      chart.unsubscribeCrosshairMove(displayTooltip);
      if (chart) {
        chart.remove();
        chart = null;
      }
      if (series) {
        series = null;
      }
      window.removeEventListener("resize", resizeHandler);
    });
    return {
      store,
      chartContainer,
    };
  },
});
</script>

    
