<template>
  <div class="card-group text-center mb-2">
    <div class="card">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="d-flex py-2">
            <div
              class="flex-shrink-0 d-flex mx-1 align-self-center"
              style="height: 36px; width: 36px"
            >
              <i
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('operation_types.asset_icon_not_available')
                "
                v-tooltip
                class="
                  bi bi-circle-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-muted
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">-</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("asset_details.asset_code") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="d-flex py-2">
            <div
              class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-piggy-bank
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">-</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("asset_details.supply") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="d-flex py-2">
            <div
              class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-coin
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3 d-flex">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">-</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("asset_details.price_usd") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="d-flex py-2">
            <div
              class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-person-square
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">-</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("asset_details.asset_holders") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="d-flex py-2">
            <div
              class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-hash
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">-</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("asset_details.rating") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AssetDetailsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
