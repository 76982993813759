/* eslint-disable vue/require-default-prop */
<template>
  <div class="card mb-2">
    <div class="card-header bg-body border-bottom-0 d-flex justify-content-between">
      <div >
        <div v-if="currentCursorCandle" class="small">
          <span class="text-secondary small">
             {{ assetCode }}/XLM
          </span>
          <span v-tooltip
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-original-title="Open"
              class="text-secondary small">
            O:
            <span :style="`color: ${currentCursorCandle.volume.color}`">
              {{ currentCursorCandle.open }}
            </span>
          </span>
          <span class="text-secondary small">
            C:
            <span :style="`color: ${currentCursorCandle.volume.color}`">
              {{ currentCursorCandle.close }}
            </span>
          </span>
          <span class="text-secondary small">
            L:
            <span :style="`color: ${currentCursorCandle.volume.color}`">
              {{ currentCursorCandle.low }}
            </span>
          </span>
          <span class="text-secondary small">
            H:
            <span :style="`color: ${currentCursorCandle.volume.color}`">
              {{ currentCursorCandle.high }}
            </span>
          </span>
        </div>
        <span v-if="currentCursorCandle" class="text-secondary small">
           <span class="small">Volume:
               <span :style="`color: ${currentCursorCandle.volume.color}`">
                 {{ formatNumberLocale(currentCursorCandle.volume.value)}}
               </span>
           </span>
        </span>
      </div>
    <div>
      <button
        v-for="(resolution, idx) in resolutions"
        :key="idx"
        :class="{
          'btn btn-sm px-1 btn-timeframe': true,
          active: resolution.text == currentResolution.text,
        }"
        @click="currentResolution = resolution"
      >
        <span class="small text-secondary">{{ resolution.text }}</span>
      </button>
    </div>
    </div>
    <div
      ref="tradeChartContainer"
      class="card-body p-0 "
      style="margin-right: 15px"> 
        <div v-if="loadingCandles" class="trade-chart-container-loading"> 
           <span class="spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
        </div>
        <div v-else-if="assetsData.length == 0" class="text-center">
            No history data.
        </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  watch,
  ref,
  onMounted,
  onUnmounted,
  computed,
} from "vue";
import { useStore } from "vuex";
import { createChart } from "lightweight-charts";
import {
  resolutions,
  candleStickChartOptions,
  scaleOptions,
  volumeSeriesOptions,
} from "@/utils/chart";
import { formatNumberLocale } from "@/utils/string";

export default defineComponent({
  name: "AssetTradesChart",
  props: {
    graph: { type: String },
    assetCode: { type: String },
    assetIssuer: { type: String },
  },
  setup(props) {
    const store = useStore();
    const tradeChartContainer = ref();
    let chart;

    const currentResolution = ref(resolutions[4]);
    const currentCursorCandle = ref(null);

    onMounted(() => {
      store.dispatch("assets/fetchAssetTradesAggregated", {
        graph: props.graph,
        resolution: currentResolution.value.resolution,
        assetCode: props.assetCode,
        assetIssuer: props.assetIssuer,
      });
      buildChart();
    });

    const assetsData = computed(() => {
        let sortedData = store.getters["assets/getAssetTradesAggregated"].map(
        (item) => {
          return {
            time: parseInt(item.timestamp / 1000),
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            volume: item.base_volume,
          };
        }
      );
      sortedData.sort((a, b) => {
        return a.time - b.time;
      });
      return sortedData;
    });
    

    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });

    const loadingCandles = computed(() => {
      return store.getters["assets/getLoadingCandlesStatus"];
    });

    const resizeHandler = () => {
      if (!chart || !tradeChartContainer.value) return;
      const dimensions = tradeChartContainer.value.getBoundingClientRect();
      chart.resize(dimensions.width, dimensions.height);
      chart.timeScale().fitContent();
    };

    const addSeriesAndData = (assetsData) => {
      const volumeData = assetsData.map((item) => {
        return {
          time: item.time,
          value: item.volume,
          color:
            item.open > item.close
              ? "rgba(239, 83, 80, 0.5)"
              : "rgba(38, 166, 154, 0.5)",
        };
      });
      const series = chart.addCandlestickSeries();
      const volumeSeries = chart.addHistogramSeries(volumeSeriesOptions);
      volumeSeries.setData(volumeData);
      series.setData(assetsData);
      chart.priceScale("").applyOptions(scaleOptions);
      if (currentCursorCandle.value == null && assetsData.length > 0) {
          chart.timeScale().fitContent();
          chart.setCrosshairPosition(
            assetsData[assetsData.length - 1],
            assetsData[assetsData.length - 1].time,
            series
          );
          const visibleLogicalRange = chart.timeScale().getVisibleLogicalRange();
          chart.timeScale().setVisibleLogicalRange({
            from: visibleLogicalRange.to / 2,
            to: visibleLogicalRange.to,
          });
      }
      chart.subscribeCrosshairMove((param) => {
        if (param.time) {
          let seriesData = param.seriesData.get(series);
          if (seriesData) {
            seriesData["volume"] = param.seriesData.get(volumeSeries);
            currentCursorCandle.value = seriesData;
          }
        }
      });
    };

    const buildChart = (remove = false) => {
      if (remove) {
          chart.remove();
      }
      chart = createChart(tradeChartContainer.value, candleStickChartOptions);
      chart.timeScale().subscribeVisibleLogicalRangeChange((event) => {
        if (event.from < 0 && loadingCandles.value == false) {
            let startTime = assetsData.value[0].time
            store.dispatch('assets/fetchAssetTradesAggregatedHistory', {
                graph: props.graph,
                resolution: currentResolution.value.resolution,
                assetCode: props.assetCode,
                assetIssuer: props.assetIssuer,
                startTime: startTime
            }) 
        }
      });
      window.addEventListener("resize", resizeHandler);
    };

    watch(
      () => assetsData.value,
      (assetsData) => {
        addSeriesAndData(assetsData);
      }
    );

    watch(
      () => currentResolution.value,
      (resolution) => {
        buildChart(true);
        store.dispatch("assets/fetchAssetTradesAggregated", {
          graph: props.graph,
          resolution: resolution.resolution,
          assetCode: props.assetCode,
          assetIssuer: props.assetIssuer,
        });
      }
    );

    return {
      store,
      tradeChartContainer,
      resolutions,
      currentResolution,
      currentCursorCandle,
      formatNumberLocale,
      loadingCandles,
      assetsData
    };
  },
});
</script>

    
