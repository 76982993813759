<template>
  <asset-stats-skeleton v-if="loading" />
  <asset-stats-skeleton v-else-if="error" />
  <template v-else>
    <div class="row g-2 mb-2">
      <div class="col-md-3">
        <div class="card">
          <div class="card-header bg-body d-flex justify-content-between">
            <span>{{ $t("asset_stats.menu") }}</span>
            <span><i class="bi bi-list"></i></span>
          </div>
          <div class="list-group list-group-flush">
            <div
              v-for="(tab, idx) in navTabs"
              :id="`nav-${tab.name}-tab`"
              :key="idx"
              :class="`list-group-item list-group-item-action
              d-flex
              gap-2
              ${!idx ? 'active' : ''}`"
              data-bs-toggle="tab"
              :data-bs-target="`#nav-${tab.name}`"
              role="tab"
              :aria-controls="`nav-${tab.name}`"
              :aria-selected="!idx ? 'true' : 'false'"
              @click="onTabChange(tab.name)"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i :class="`bi ${tab.icon}`"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  {{ $t(`asset_stats.${tab.name}`) }}
                </div>
              </div>
              <span
                v-if="tab.name !== 'overview'"
                class="badge badge-body ms-auto  border fw-normal"
                ><span class="text-body">{{ tab.value }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div v-if="currTab === 'overview'" class="card">
          <div class="card-header bg-body d-flex justify-content-between">
            <span>{{ $t("asset_stats.asset_information") }}
                <a :href="'/contracts/' + decodeStringContract(assetDetails.contract_id)">
                    <span v-if="assetDetails.contract_id" class="badge bg-body-secondary text-body">Stellar Asset Contract</span>
                </a>
            </span>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                  <i class="bi bi-person-square me-3"></i
                  >{{ $t("asset_stats.asset_issuer") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <template v-if="!isNative"
                    ><router-link
                      class="align-self-center small font-monospace text-decoration-none"
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: { acc_id: assetDetails.asset_issuer },
                      }"
                      >{{ assetDetails.asset_issuer }}</router-link
                    ><router-link
                      to="#"
                      class="float-end text-decoration-none"
                      @click="copy(assetDetails.asset_issuer)"
                      >{{ $t("asset_stats.copy") }}</router-link
                    ></template
                  >
                  <div v-else>{{ assetDetails.asset_issuer }}</div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                  <i class="bi bi-tag-fill me-3"></i
                  >{{ $t("asset_stats.label") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <span
                    class="text-decoration-none font-monospace fw-bold  small"
                  >
                    {{ assetDetails?.meta?.label?.name ?? "-" }}
                  </span>
                  <i
                    v-tooltip
                    class="bi ms-5"
                    :class="labelData?.icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="labelData?.tooltip"
                  ></i>
                  <router-link
                    v-if="!isNative"
                    :to="{
                      name: 'DirectoryUpdateRoute',
                      query: {
                        account: assetDetails.asset_issuer,
                      },
                    }"
                    class="float-end text-decoration-none"
                    >{{ $t("transaction_stats.update") }}
                  </router-link>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                  <i class="bi bi-globe me-3"></i
                  >{{ $t("asset_stats.website") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <div
                    class="small font-monospace text-decoration-none font-monospace"
                  >
                    <a
                      :href="website(assetDetails)"
                      class="text-decoration-none"
                      target="_blank"
                      >{{ website(assetDetails) }}</a
                    >
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i
                  >{{ $t("asset_stats.payments") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div
                    class="small font-monospace text-decoration-none font-monospace"
                  >
                    {{
                      assetDetails?.meta?.payments
                        ? parseFloat(
                            assetDetails?.meta?.payments
                          ).toLocaleString()
                        : "-"
                    }}
                  </div>
                </div>
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i
                  >{{ $t("asset_stats.volume") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div
                    class="small font-monospace text-decoration-none font-monospace"
                  >
                    {{
                      assetDetails?.meta?.volume
                        ? parseFloat(
                            assetDetails?.meta?.volume
                          ).toLocaleString()
                        : "-"
                    }}
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                  <i class="bi bi-arrow-left-right me-3"></i
                  >{{ $t("asset_stats.trades") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div
                    class="small font-monospace text-decoration-none font-monospace"
                  >
                    {{
                      assetDetails?.meta?.trades
                        ? parseFloat(
                            assetDetails?.meta?.trades
                          ).toLocaleString()
                        : "-"
                    }}
                  </div>
                </div>
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-left-right me-3"></i
                  >{{ $t("asset_stats.volume") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div
                    class="small font-monospace text-decoration-none font-monospace"
                  >
                    {{
                      assetDetails?.meta?.volume_usd
                        ? parseFloat(
                            assetDetails?.meta?.volume_usd
                          ).toLocaleString()
                        : "-"
                    }}
                    USD
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <asset-stats-chart
          v-else
          ref="assetStatsChart"
          :graph="currTab"
          :asset-code="$route.params.asset_code"
          :asset-issuer="$route.params.asset_issuer"
        />
      </div>
    </div>
    <div class="row g-2 mb-2">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body table-card" style="padding: 5px">
            <table
              class="table table-responsive table-hover table-trades table-borderless"
            >
              <thead>
                <tr>
                  <th scope="col" class="small text-secondary">Price (XLM)</th>
                  <th scope="col" class="text-end small text-secondary">
                    Amount({{ assetDetails.asset_code }})
                  </th>
                  <th scope="col" class="small text-secondary text-end">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(orderAsk, id) in orderedAsks"
                  :key="id"
                  class="small font-monospace"
                  :style="getBackgroundStyle(orderAsk, 'asks')"
                >
                  <td class="text-danger">
                    {{ orderAsk.price }}
                  </td>
                  <td class="text-end">
                    {{ parseFloat(orderAsk.amount).toLocaleString() }}
                  </td>
                  <td class="text-end">
                    {{ (orderAsk.price * orderAsk.amount).toLocaleString() }}
                  </td>
                </tr>
                <caption class="d-flex">
                  <strong>{{parseFloat(get(orderedBids, "0.price", 0)).toLocaleString()}}</strong>
                  <span> XLM</span>
                </caption>
                <tr
                  v-for="(orderBid, id) in orderedBids"
                  :key="id"
                  class="small font-monospace"
                  :style="getBackgroundStyle(orderBid, 'bids')"
                >
                  <td class="text-success">
                    {{ orderBid.price }}
                  </td>
                  <td class="text-end">
                    {{ parseFloat(orderBid.amount).toLocaleString() }}
                  </td>
                  <td class="text-end">
                    {{ (orderBid.price * orderBid.amount).toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div v-if="$route.params.asset_code != 'XLM'" class="trades-chart position-sticky" style="top: 60px">
          <asset-trades-chart
            ref="assetTradesChart"
            :graph="currTab"
            :asset-code="$route.params.asset_code"
            :asset-issuer="$route.params.asset_issuer"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div
          v-if="orderedTrades && orderedTrades.length"
          class="card"
          style="height: 100%"
        >
          <div class="small card-header bg-body blank-header">Market trades</div>
          <div class="card-body table-card" style="height: 100%;padding: 5px">
            <div class="relative" style="height: 100%">
              <div class="market-trades">
                <table
                  class="table table-responsive table-hover table-trades table-borderless"
                >
                  <thead>
                    <tr>
                      <th scope="col" class="small text-secondary">
                        Price (XLM)
                      </th>
                      <th scope="col" class="small text-secondary text-end">
                        Amount({{ assetDetails.asset_code }})
                      </th>
                      <th scope="col" class="small text-secondary text-end">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(trade, id) in orderedTrades"
                      :key="id"
                      class="cp small font-monospace"
                      @click="goToOperation(trade)"
                    >
                      <td>
                        <span
                          :class="`${
                            trade.trend == 2 ? 'text-danger' : 'text-success'
                          }`"
                        >
                          {{
                            parseFloat(
                              trade.price.n / trade.price.d
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 12,
                            })
                          }}
                        </span>
                      </td>
                      <td class="text-end">
                        <div v-if="trade.base_is_seller">
                          {{ parseFloat(trade.base_amount).toLocaleString() }}
                        </div>
                        <div v-else>
                          {{
                            parseFloat(trade.counter_amount).toLocaleString()
                          }}
                        </div>
                      </td>
                      <td class="text-end">
                        {{ getTimeDifference(trade.ledger_close_time) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { splitAndCapitalize } from "@/utils/string.js";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { get } from "lodash";
import { StrKey } from 'stellar-sdk';
import { formatNumberLocale } from "@/utils/string";
import AssetStatsSkeleton from "@/components/skeletons/stats/AssetStatsSkeleton";
import AssetStatsChart from "@/components/charts/AssetStatsChart.vue";
import AssetTradesChart from "@/components/charts/AssetTradesChart.vue";

export default defineComponent({
  name: "AssetDetails",
  components: {
    AssetStatsChart,
    AssetStatsSkeleton,
    AssetTradesChart,
  },
  props: {
    isNative: Boolean,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const assetStatsChart = ref();
    const currTab = ref("overview");

    const assetDetails = computed(
      () => store.getters["assets/getSelectedAsset"].data
    );

    const getTimeDifference = (ledger_time) => {
      const now = new Date();
      const seconds = parseInt(Math.abs(now - new Date(ledger_time)) / 1000);

      if (seconds < 60) {
        return seconds + "s ago";
      }

      const minutes = parseInt(seconds / 60);
      if (minutes < 60) {
        return minutes + "m ago";
      }

      const hours = parseInt(minutes / 60);
      if (hours < 24) {
        return hours + "h ago";
      }

      const days = parseInt(hours / 24);
      if (days < 30) {
        return days + "d ago";
      }

      const months = parseInt(days / 30);
      if (months < 12) {
        return months + "mo ago";
      }

      const years = parseInt(months / 12);

      return years + "y ago";
    };

    watch(
      () => assetDetails.value,
      (newValue, oldValue) => {
        console.log(newValue, oldValue);
        if (newValue !== oldValue) {
          store.dispatch("assets/fetchAssetOffers", {
            asset_code: assetDetails.value.asset_code,
            asset_issuer: assetDetails.value.asset_issuer,
          });
          store.dispatch("assets/fetchAssetLiquidityPools", {
            asset_code: assetDetails.value.asset_code,
            asset_issuer: assetDetails.value.asset_issuer,
          });
          store.dispatch("assets/fetchAssetOrderBook", {
            asset_code: assetDetails.value.asset_code,
            asset_issuer: assetDetails.value.asset_issuer,
          });
          store.dispatch("assets/fetchAssetTrades", {
            asset_code: assetDetails.value.asset_code,
            asset_issuer: assetDetails.value.asset_issuer,
          });
          store.dispatch("assets/startStreamingTrades", {
            asset_code: assetDetails.value.asset_code,
            asset_issuer: assetDetails.value.asset_issuer,
          });
          store.dispatch("assets/startStreamingOrderbook", {
            asset_code: assetDetails.value.asset_code,
            asset_issuer: assetDetails.value.asset_issuer,
          });
        }
      }
    );

    const orderBook = computed(() => store.getters["assets/getOrderBook"]);

    const latestTrades = computed(() => store.getters["assets/getAssetTrades"]);

    const loading = computed(
      () => store.getters["assets/getSelectedAsset"].loading
    );
    const error = computed(
      () => store.getters["assets/getSelectedAsset"].error
    );

    const navTabs = computed(() => [
      { name: "overview", value: null, icon: "bi-app-indicator" },
      {
        name: "supply",
        value: assetDetails.value?.meta?.supply
          ? parseFloat(assetDetails.value?.meta?.supply).toLocaleString()
          : "-",
        icon: "bi-piggy-bank",
      },
      {
        name: "holders",
        value: assetDetails.value?.meta?.holders
          ? parseFloat(assetDetails.value?.meta?.holders).toLocaleString()
          : "-",
        icon: "bi-person-square",
      },
      {
        name: "price",
        value: assetDetails.value?.meta?.price_usd
          ? "$ " + parseFloat(assetDetails.value?.meta?.price_usd).toFixed(4)
          : "$ -",
        icon: "bi-coin",
      },
      {
        name: "payments",
        value: assetDetails.value?.meta?.payments
          ? parseFloat(assetDetails.value?.meta?.payments).toLocaleString()
          : "-",
        icon: "bi-arrow-right-circle-fill",
      },
      {
        name: "trades",
        value: assetDetails.value?.meta?.trades
          ? parseFloat(assetDetails.value?.meta?.trades).toLocaleString()
          : "-",
        icon: "bi-arrow-left-right",
      },
    ]);
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };

    const labelData = computed(() => {
      if (assetDetails.value?.meta?.label) {
        if (["Scam", "Hack"].includes(assetDetails.value?.meta?.label?.name)) {
          return {
            icon: "bi-exclamation-triangle-fill text-danger",
            tooltip: t("transaction_stats.scam_tooltip", {
              type: assetDetails.value?.meta?.label?.name.toUpperCase(),
            }),
          };
        }

        if (assetDetails.value?.meta?.label.verified) {
          return {
            icon: "bi-patch-check-fill text-primary",
            tooltip: t("account_summary.verified_account"),
          };
        } else {
          return {
            icon: "bi-person-circle ",
            tooltip: t("account_summary.unverified_user_defined_label"),
          };
        }
      }
      return "-";
    });

    const website = (asset) => {
      return asset?._links?.toml?.href.split(".well-known")[0] ?? "-";
    };
    const onTabChange = (tab) => {
      switch (tab) {
        case "overview":
          currTab.value = "overview";
          break;
        case "supply":
          currTab.value = "gf_supply";
          break;
        case "holders":
          currTab.value = "gf_holders";
          break;
        case "price":
          currTab.value = "gf_price_usd";
          break;
        case "payments":
          currTab.value = "gf_payments";
          break;
        case "trades":
          currTab.value = "gf_trades";
          break;
      }
    };

    const goToOperation = (trade) => {
      trade.operation().then((operation) => {
        console.log(operation);
        router.push({
          name: "OpDetailsRoute",
          params: {
            op_id: operation.id,
          },
        });
      });
    };

    const orderedAsks = computed(() => {
      if (orderBook.value?.asks) {
        let asks = [...orderBook.value.asks];
        return asks
          .sort((a, b) => {
            return parseFloat(b.price) - parseFloat(a.price);
          })
          .slice(-20);
      }
      return [];
    });

    const orderedBids = computed(() => {
      if (orderBook.value?.bids) {
        let bids = [...orderBook.value.bids].slice(0, 20);
        return bids;
      }
      return [];
    });

    const proxyToObject = (proxy) => {
      const obj = {};
      for (const key of Object.keys(proxy)) {
        obj[key] = proxy[key];
      }
      return obj;
    };

    const orderedTrades = computed(() => {
      if (latestTrades.value.records) {
        let trades = [...latestTrades.value.records];
        let previousPrice = null;
        let newTrades = [];
        for (const trade of trades) {
          let newTrade = proxyToObject(trade);
          if (previousPrice === null) {
            newTrade.trend = 2;
          } else {
            const currentPrice =
              parseFloat(newTrade.price.n) / parseFloat(newTrade.price.d);
            const previousTradePrice =
              parseFloat(previousPrice.n) / parseFloat(previousPrice.d);
            if (currentPrice > previousTradePrice) {
              newTrade.trend = 1;
            } else if (currentPrice < previousTradePrice) {
              newTrade.trend = 2;
            } else {
              newTrade.trend = 1;
            }
          }

          previousPrice = newTrade.price;
          newTrades.push(newTrade);
        }
        return newTrades;
      }
      return [];
    });

    const calculateTotalDepth = (side, price) => {
      let totalDepth = 0;
      for (const order of orderBook.value[side]) {
        if (side == "asks" && order.price <= price) {
          totalDepth += parseFloat(order.amount);
        } else if (side == "bids" && order.price >= price) {
          totalDepth += parseFloat(order.amount);
        } else {
          break;
        }
      }
      return totalDepth;
    };

    const getBackgroundStyle = (order, side) => {
      const totalDepth = calculateTotalDepth(side, order.price);
      const percentage = (order.amount / totalDepth) * 100;
      return `background-image: linear-gradient(to left, ${
        side == "bids" ? "rgba(0, 186, 0, 0.15)" : "rgba(223, 0, 0, 0.15)"
      } ${percentage}%, transparent ${percentage}%);`;
    };

    const decodeStringContract = (contractId) => {
        if (contractId && contractId.length > 0) {
            return StrKey.decodeContract(contractId).toString('hex')
        }
        return '-';
    }

    return {
      assetDetails,
      loading,
      error,
      website,
      splitAndCapitalize,
      copy,
      getTimeDifference,
      labelData,
      navTabs,
      currTab,
      onTabChange,
      assetStatsChart,
      orderBook,
      latestTrades,
      goToOperation,
      orderedAsks,
      orderedBids,
      orderedTrades,
      getBackgroundStyle,
      get,
      formatNumberLocale,
      StrKey,
      decodeStringContract
    };
  },
});
</script>
