<template>
  <asset-details-skeleton v-if="loading" />
  <asset-details-skeleton v-else-if="error" />
  <template v-else>
    <div class="d-flex mb-2">
        <div class="flex-fill">
          <div class="input-group">
            <search />
          </div>
        </div>
    </div>
    <div class="card-group text-center mb-2">
      <div class="card">
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="d-flex py-2">
              <div
                class="flex-shrink-0 d-flex mx-1 align-self-center"
                style="height: 36px; width: 36px"
              >
                <img
                  v-if="$route.params.asset_issuer === 'native'"
                  v-tooltip
                  :src="xlmLogo"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_icon')"
                  class="px-1 align-self-center mx-auto img-fluid"
                />
                <img
                  v-else-if="assetDetails?.meta?.image"
                  v-tooltip
                  :src="assetDetails?.meta?.image"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_icon')"
                  class="px-1 align-self-center mx-auto img-fluid"
                />
                <i
                  v-else
                  v-tooltip
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="
                    $t('operation_types.asset_icon_not_available')
                  "
                  class="
                    bi bi-circle-fill
                    fs-5
                    align-self-center
                    mx-auto
                    text-muted
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="fw-bold mb-0 d-block font-monospace">
                    {{ assetDetails?.asset_code }}
                  </span>
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("asset_details.asset_code") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="d-flex py-2">
              <div
                class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-piggy-bank
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-primary
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span
                    class="fw-bold mb-0 d-block font-monospace"
                    >{{
                      assetDetails?.meta?.supply
                        ? parseFloat(
                            assetDetails?.meta?.supply
                          ).toLocaleString()
                        : "-"
                    }}</span
                  >
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("asset_details.supply") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="d-flex py-2">
              <div
                class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-coin
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-primary
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3 d-flex">
                <div class="float-start text-start">
                  <span class="fw-bold mb-0 d-block">{{
                    assetDetails?.meta?.price_usd
                      ? parseFloat(assetDetails?.meta?.price_usd).toFixed(4)
                      : "-"
                  }}</span>
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("asset_details.price_usd") }}</span
                  >
                </div>
                <div class="ms-auto align-self-center">
                  <span
                    class="badge bg-opacity-25 align-self-center"
                    :class="{
                      'text-danger bg-danger':
                        assetDetails?.meta?.price_usd_change < 0,
                      'text-secondary bg-secondary':
                        assetDetails?.meta?.price_usd_change === 0,
                      'text-success bg-success':
                        assetDetails?.meta?.price_usd_change > 0,
                    }"
                    ><i
                      class="bi"
                      :class="{
                        'bi-caret-down-fill':
                          assetDetails?.meta?.price_usd_change < 0,
                        'bi-caret-right-fill':
                          assetDetails?.meta?.price_usd_change === 0,
                        'bi-caret-up-fill':
                          assetDetails?.meta?.price_usd_change > 0,
                      }"
                    ></i>
                    {{ assetDetails?.meta?.price_usd_change }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="d-flex py-2">
              <div
                class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-person-square
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-primary
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="fw-bold mb-0 d-block">{{
                    assetDetails?.meta?.holders
                      ? parseFloat(assetDetails?.meta?.holders).toLocaleString()
                      : "-"
                  }}</span>
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("asset_details.asset_holders") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="d-flex py-2">
              <div
                class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex"
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-hash
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-primary
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="fw-bold mb-0 d-block">{{
                    assetDetails?.meta?.rating ?? "-"
                  }}</span>
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("asset_details.rating") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></template
  >
  <asset-stats :is-native="$route.params.asset_issuer === 'native'" />
</template>


<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import AssetStats from "@/components/stats/AssetStats.vue";
import Search from "@/components/Search.vue";
import AssetDetailsSkeleton from "@/components/skeletons/stats/AssetDetailsSkeleton.vue";
export default defineComponent({
  name: "AssetDetails",
  components: {
    Search, 
    AssetStats,
    AssetDetailsSkeleton,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const currTab = ref("operations");
    store.dispatch("assets/fetchAssetByCodeAndIssuer", {
      asset_code: route.params.asset_code,
      asset_issuer: route.params.asset_issuer,
    });
    const assetDetails = computed(
      () => store.getters["assets/getSelectedAsset"].data
    );
    const loading = computed(
      () => store.getters["assets/getSelectedAsset"].loading
    );
    const error = computed(
      () => store.getters["assets/getSelectedAsset"].error
    );

    const onTabChange = (tab) => {
      currTab.value = tab.name;
    };

    const navTabs = computed(() => [
      { name: "operations", icon: "bi-view-list" },
      { name: "payments", icon: "bi-arrow-right-circle-fill" },
      { name: "offers", icon: "bi-file-text" },
      { name: "trades", icon: "bi-arrow-left-right" },
      { name: "trustlines", icon: "bi-clipboard2-check" },
      { name: "market", icon: "bi-shop" },
      { name: "holders", icon: "bi-person-square" },
      { name: "other", icon: "bi-file-earmark-text" },
    ]);
    return {
      assetDetails,
      loading,
      error,
      xlmLogo: require("@/assets/images/assets/xlm.png"),
      navTabs,
      currTab,
      onTabChange,
    };
  },
});
</script>
